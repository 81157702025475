/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

export const COMMON_NOTIFICATION = "COMMON_NOTIFICATION";
export const GET_USER_ACTIVITY_HISTORY = "GET_USER_ACTIVITY_HISTORY";
export const GET_CRM_SUPPORT_AGENT = "GET_CRM_SUPPORT_AGENT";
export const GET_USERNAME_BY_NUMBER = "GET_USERNAME_BY_NUMBER";
export const GET_SIM_DETAILS = "GET_SIM_DETAILS";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_NOTIFICATION = "LOGIN_NOTIFICATION";
export const VERIFY_USER = "VERIFY_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const GUEST_USER_SUCCESS = "GUEST_USER_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const VERIFY_RESET_LINK = "VERIFY_RESET_LINK";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* Account */
export const GET_USER_DETAILS = "get_user_details";

export const GET_CITY = "get_city";
export const INSERT_CUSTOMER = "insert_customer";
export const UPDATE_CUSTOMER = "update_customer";

/* cart */

export const ADD_CART = "add_cart";
export const UPDATE_CART = "update_cart";
export const GET_CART = "get_cart";
export const REMOVE_CART = "remove_cart";

/* payment */
export const STORE_PAYMENT = "store_payment";

/* CUSTOMER SUPPORT DASHBOARD*/
export const GET_BALANCE = "get_balance";
export const SUBSCRIPTION_INFO = "subscription_info";
export const COUNTER_QUERY = "counter_query";
export const CHANGE_OPTION = "change_option";
export const CRM_VOUCHER_TOPUP_REPORT = "crm_vouche_topup_report";

/*End Customer */
export const GET_END_USER = "get_end_user";
export const GET_UNIQUE_END_USER = "get_unique_end_user";
export const UPDATE_END_USER = "update_end_user";

/* Voucher*/
export const TRANSFER_BALANCE = "transfer_balance";
export const GET_VOUCHER_STATUS = "get_voucher_status";
export const VOUCHER_STATUS_TYPE = "voucher_status_type";
export const CHANGE_STATUS = "change_status";

/* Tickets */
export const GET_TICKET_COUNT = "get_ticket_count";
export const GET_OPEN_TICKET = "get_open_ticket";
export const GET_CLOSE_TICKET = "get_close_ticket";
export const GET_USER_TICKET = "get_user_ticket";
export const GET_ANSWER_TICKET = "get_answer_ticket";
export const MANAGE_SUPPORT_RESPONSE = "manage_support_response";
export const CHANGE_TICKET_STATUS = "change_ticket_status";
export const GET_TICKET_TYPES = "get_ticket_types";
export const MANAGE_SUPPORT_QUESTION = "manage_support_question";
export const GET_ALL_TICKET = "get_all_ticket";
export const GET_TICKET_DETAILS = "get_ticket_details";
export const TICKET_NOTIFICATION = "TICKET_NOTIFICATION";

/* Sim services */
export const MANAGE_MNP_PORTOUT = "manage_mnp_portout";
export const ADJUST_BALANCE = "adjust_balance";
export const SIM_SWAP = "sim_swap";
export const MSISDN_SWAP = "msisdn_swap";
export const GET_CUSTOMER_DETAILS_BY_MOBNUM = "GET_CUSTOMER_DETAILS_BY_MOBNUM";
export const FUND_TRANSFER_REQUEST = "FUND_TRANSFER_REQUEST";
export const GET_FUND_TRANSFER_LIST = "GET_FUND_TRANSFER_LIST";

/* CDR */
export const GET_CALL_RECORD = "get_call_record";
export const GET_PADS_RECORD = "get_pads_record";
export const GET_SUBSCRIPTION_RECORD = "get_subscription_record";
export const GET_ACCOUNT_COUNT_RECORD = "get_account_count_record";
export const GET_ADJUSTMENT_TYPE_RECORD = "get_adjustment_type_record";
export const GET_SMS_RECORD = "get_sms_record";
export const GET_CDR_TYPE = "get_cdr_type";

/* Portout */
export const GET_LIST_PARTITIONS = "get_list_partitions";
export const SET_OPTIN = "set_optin";
export const REPLY_TO_PARTITIONS = "reply_to_partitions";

/* PortIn */
export const MANAGE_MNP_PORTIN = "manage_mnp_portin";
export const GET_CUSTOMER_INFO = "get_customer_info";
export const PORTIN_PRIVATE = "portin_private";
export const PORTIN_PRIVATE_ASAP = "portin_private_asap";
export const PORTIN_PRIVATE_VTEN = "portin_private_vten";
export const PORTIN_COMPANY = "portin_company";
export const PORTIN_COMPANY_ASAP = "portin_company_asap";
export const PORTIN_COMPANY_VTEN = "portin_company_vten";
export const GET_LISTPORTATION_PORTIN = "GET_LISTPORTATION_PORTIN";
export const PORTIN_COMMONE = "PORTIN_COMMONE";
export const GET_PROVIDERS_LIST = "GET_PROVIDERS_LIST";

/* Notification */
export const GET_NOTIFICATION_COUNT = "get_notification_count";
export const GET_NOTIFICATION_LIST = "get_notification_list";
export const GENERATE_TICKET = "generate_ticket";

/* EngHouse */

export const GET_ENG_MOBILENUMBER = "GET_ENG_MOBILENUMBER";
export const GET_ENG_OPTIN = "GET_ENG_OPTIN";
export const GET_ENG_PORTIN = "GET_ENG_PORTIN";
export const GET_ENG_CUSTOMER = "GET_ENG_CUSTOMER";
export const GET_PORTOUT_RECORDS = "GET_PORTOUT_RECORDS";
export const CHANGE_USER_NOTIFICATION = "change_user_notification";
export const PORTOUT_COMMONE = "PORTOUT_COMMONE";
export const GET_PORTIN_LIST = "GET_PORTIN_LIST";
export const INITIATE_PORTIN = "INITIATE_PORTIN";
export const GET_PORTIN_ICCID = "GET_PORTIN_ICCID";


export const GET_NECT_CUSTOMERS = "GET_NECT_CUSTOMERS";
